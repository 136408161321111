<template>
    <div class="relative">
        <div class="w-full absolute block z-20">
            <Header/>
        </div>
    <div class="contact-banner-container space-top w-full">
        <div class="relative h-full w-full flex justify-center flex-col">
            <img class="absolute top-0 right-0 h-full object-cover z-5 lg:mr-8" src="../../../assets/images/contact-us/contactus-banner.png" alt="Contact Us Banner">
            <div class="flex flex-col mb-6 pr-8 mobileL:pr-0 mobileL:mb-0 z-10 max-w-xs md:max-w-xl 2xl:max-w-3xl p-0 mobileL:p-4 text-start mobileL:text-center ml-0 lg:ml-16 2xl:ml-20 mx-auto">
                <h1 class="contact-banner-title flex mobileL:static mobileL:justify-center ml-6 mobileS:text-2xl xs:text-3xl md:text-4xl lg:text-6xl 2xl:text-8xl text-white-1">
                    Contact Us
                </h1>
                <p class="mobileL:mt-4 text-sm sm:text-base md:text-xl lg:text-xl xl:text-2xl 2xl:text-4xl ml-2 px-4 mr-6 mobileL:mr-0 text-white-1">
                    Have questions or comments? We will get back to you as soon as we can!
                </p>
            </div>         
        </div>
    </div>
    <div class="relative">
        <img class="absolute inset-0 w-full h-full object-cover" src="../../../assets/images/home-page/plc-perks/body-background.png" alt="Background Image">
        <div class="relative text-brown-4">
            <div class="items-center text-center">
                <h2 class="font-tahu text-4xl md:text-5xl lg:text-6xl 2xl:text-8xl pt-6 mobileL:pt-8">We are always ready to help!</h2>
                <div class="text-center md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl">
                    <p class="mt-3 mobileL:mt-6 mx-4 mobileL:mx-20 xl:mx-32 2xl:mx-80"> 
                        You may email us at <a class="font-semibold" href="mailto:customercare@lay-bare.com">customercare@lay-bare.com</a>
                        and we’ll get in touch with you from Mondays to Fridays, 9AM-6PM.
                    </p>
                    <p class="mt-4 mobileL:mt-8 font-semibold">Keep in touch in our social media platforms!</p>
                </div>
                <div class="pb-6 mobileL:pb-8 flex items-center justify-center mt-4 mobileL:mt-12 2xl:mt-16">
                    <ol class="flex gap-2 mobileL:gap-8">
                        <li><a href="https://www.facebook.com/OfficialLayBare/">
                            <img class="w-9 mobileL:w-16 2xl:w-24" src="../../../assets/images/contact-us/facebook.png" alt="Facebook Logo">
                        </a></li>
                        <li><a href="https://www.instagram.com/lay_bare/">
                            <img class="w-9 mobileL:w-16 2xl:w-24" src="../../../assets/images/contact-us/instagram.png" alt="Instagram Logo">
                        </a></li>
                        <li><a href="https://twitter.com/laybareph">
                            <img class="w-9 mobileL:w-16 2xl:w-24" src="../../../assets/images/contact-us/twitter.png" alt="Twitter Logo">
                        </a></li>
                        <li><a href="https://www.tiktok.com/@officiallaybare">
                            <img class="w-9 mobileL:w-16 2xl:w-24" src="../../../assets/images/contact-us/tiktok.png" alt="Instagram Logo">
                        </a></li>
                    </ol>   
                </div>
            </div>
        </div>
    </div>
    <div class="w-full absolute block z-20">
        <Footer/>
    </div>
  </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/LaybareFooter.vue'

export default {
  components: { Header,  Footer },

  mounted() {
    this.$gtag.pageview(this.$route);
  },
}
</script>

<style>
.contact-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #F2D0B7 0%, #D9AC8D 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .contact-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

@media (min-width: 1536px) {
    .contact-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    } 
}

@media (max-width:480px) {
    .contact-banner-container{
        height:255px;
    }
    .space-top {
    margin-top: 60px;
    }
}

.contact-banner-title {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 4px 0px #C58965;
    font-family: Century Gothic;
    font-style: normal;
    font-weight: 600;
}

@media only screen and (min-width: 768px) {
    .contact-banner-title {
        text-shadow: 0px 6px 0px #C58965;
    }
}

@media only screen and (min-width: 992px) {
    .contact-banner-title {
        text-shadow: 0px 8px 0px #C58965;
    }
}

@media only screen and (min-width: 1200px) {
    .contact-banner-title {
        text-shadow: 0px 10px 0px #C58965;
    }
}

</style>